export const routes = [
  {
    path: '/data-table',
    component: render('frontend/DataTable'),
  },
  {
    path: '/landing',
    component: render('frontend/MainMenu'),
  },
  {
    path: '/android-guide',
    component: render('frontend/AndroidGuide'),
  },
  {
    path: '/ios-guide',
    component: render('frontend/IOSGuide'),
  },
  {
    path: '/',
    component: render('frontend/Index'),
    children: [
      {
        path: '',
        component: render('frontend/Home'),
        // redirect: 'home'
      },
      {
        path: 'auth/login',
        component: render('frontend/auth/Login'),
      },
      {
        path: 'auth/register',
        component: render('frontend/auth/Register'),
      },
      {
        path: 'auth/verify',
        component: render('frontend/auth/Verify'),
      },
      {
        path: 'auth/not-auth-verify',
        component: render('frontend/auth/VerifyNotAuth'),
      },
      {
        path: 'home',
        component: render('frontend/Home'),
        
      },
      {
        path: 'rule',
        component: render('frontend/auth/Rule'),
        
      },
      {
        path: 'slots',
        component: render('frontend/normal-user/game/Slot'),
      },
      {
        path: 'fishing',
        component: render('frontend/normal-user/game/Fishing'),
      },
      {
        path: 'betslip-history',
        component: render('frontend/BetslipHistory'),
        
      },
      {
        path: 'live-score',
        component: render('frontend/LiveScore'),
      },
      {
        path: 'history',
        component: render('frontend/History'),
        
      },
      {
        path: 'maung-fb',
        component: render('frontend/MaungFB'),
        
      },
      {
        path: 'body-fb',
        component: render('frontend/BodyFB'),
        
      },
      {
        path: 'setting',
        component: render('frontend/Setting'),
        
      },
      {
        path: 'contact-us',
        component: render('frontend/ContactUs'),
        
      },
      {
        path: 'promotion',
        component: render('frontend/Promotion'),
        
      },
      {
        path: 'live',
        component: render('frontend/Live'),
        
      },
      {
        path: 'video-links',
        component: render('frontend/VideoLink'),
        
      },
      {
        path: 'deposit',
        component: render('frontend/Deposit'),
        
      },
      {
        path: 'withdraw',
        component: render('frontend/Withdraw'),
      },
      {
        path: 'banking',
        component: render('frontend/Banking'),
      },
      {
        path: 'commission',
        component: render('frontend/Commission'),
      },
      {
        path: 'level',
        component: render('frontend/VipLevels'),
      },
      {
        path: 'iframeURL',
        component: render('frontend/Iframe'),
        
      },
      {
        path: '/user',
        component: render('frontend/normal-user/Index'),
        // meta: { auth: true },
        children: [
          {
            path: 'dream-books',
            component: render('frontend/normal-user/DreamBooks'),
          },
          {
            path: '2d/home',
            component: render('frontend/normal-user/2d/Home'),
          },

          {
            path: '2d',
            component: render('frontend/normal-user/2d/2d'),
          },
          {
            path: '2d/closed',
            component: render('frontend/normal-user/2d/ClosedDays'),
          },
          {
            path: '2d/winner',
            component: render('frontend/normal-user/2d/Winner'),
          },
          {
            path: '3d/home',
            component: render('frontend/normal-user/3d/Home'),
          },
          {
            path: '3d',
            component: render('frontend/normal-user/3d/3d'),
          },
          {
            path: 'slots',
            component: render('frontend/normal-user/game/Slot'),
          },
          {
            path: 'arcade',
            component: render('frontend/normal-user/game/Arcade'),
          },
          {
            path: 'game',
            component: render('frontend/normal-user/game/Game'),
          },
          {
            path: 'fishing',
            component: render('frontend/normal-user/game/Fishing'),
          },
          {
            path: 'skm-rooms',
            component: render('frontend/normal-user/shankomee/Room'),
          },
          {
            path: 'bugyee-rooms',
            component: render('frontend/normal-user/bugyee/Room'),
          },
        ],
      },
    ],
  },
];

function render(template) {
  return require(`@/pages/${template}.vue`).default;
}
