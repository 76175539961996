<template>
  <div class="container-fluid p-2 pt-3" style="scrollbar-width: none; -ms-overflow-style: none; background: white; height: 100vh; overflow-y: scroll; background-size: cover; padding-bottom: 15rem; background-attachment: fixed;">
        <div style="height: 280vh;">
          <!-- <div class="my-3 d-flex flex-wrap px-0 mx-0">
            <button class="detail-button w-auto text-nowrap mx-1 my-1">ALL</button>
            <button class="detail-button w-auto text-nowrap my-1">NEW MEMBER</button>
            <button class="detail-button w-auto text-nowrap mx-1 my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap mx-1 my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap my-1">LIVE CASINO</button>
          </div> -->
          <div class="col-12 px-1 mb-5 pb-5">
            <div class="card bg-transparent mt-2" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; box-shadow: none;" v-for="(promotion, proIndex) in promotions" :key="proIndex">
              <a href="#">
                <!-- https://t.me/hulk333vip -->
                <img :src="promotion.image" style="border-radius: 5px; width: 100%;" alt="">
              </a>
                <div class="px-3 py-2 d-flex justify-content-between bg-transparent">
                  <h3 class="text-dark my-auto">
                    <!-- <picture class="mr-2">
                      <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.webp" type="image/webp">
                      <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.gif" alt="💸" width="25" height="25">
                    </picture> -->
                    {{ promotion.title }}
                    <!-- <picture class="ml-2">
                      <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.webp" type="image/webp">
                      <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.gif" alt="💸" width="25" height="25">
                    </picture> -->
                  </h3>
                  <button class="detail-button col-3" @click=" promotionIndex(proIndex)">{{$t('detail')}}</button>
                </div>

                <van-overlay :show="selectedPromotion === proIndex" @click="selectedPromotion = null">
                    <div class="wrapper col-12 d-flex h-100vh">
                      <div class="col-11 card mx-auto my-auto py-4 px-3" style="background: #202124 !important;">
                        <h2 class="pb-3" style="color: #d3d3d3"> {{ promotion.title }}</h2>
                        <span style="color: #B7AAAA" v-html="promotion.description"></span>
                      </div>
                    </div>
                </van-overlay>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      // websiteInfo: [],
      isLoading: false,
      selectedPromotion: null,
      promotions: [
        {
          title: 'သင်္ကြန်ပိုမိုရိုးရှင်း (တစ်နေ့1ကြိမ်)',
          description: `📌 အနည်းဆုံး10,000ကစသွင်းပါကဒီပိုမိုရိုးရှင်းရနိုင်ပါတယ်ရှင့် <br><br>
                        📌 အမြင့်ဆုံးရယူနိုင်သည့် Bonus မှာ ငွေကျပ် 30,000 ဖြစ်ပါသည် <br><br>
                        📌 10,000 သွင်း 22,000 ရ ။<br><br>
                        📌 20,000 သွင်း 44,000 ရ ။<br><br>
                        📌 30,000 သွင်း 66,000 ရ ။<br><br>
                        📌 နိုင်ငွေ 5ဆ ပြည့်မှသာ ငွေထုတ်ပေးပါမည် <br><br>
                        📌 နိုင်ငွေ ပြည့်ပြီးပါက ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။ <br><br>
                        📌 ဤပရိုမိုးရှင်းသည် ကာစီနိုမှလွဲ၍အခြားဂိမ်းများအားလုံးကစားနိုင်ပါသည်။`,
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/banners/1_promo.jpg',
        },
        {
          title: 'လူကြိုက် WoWဘောနပ် တစ်နေ့(2ကြိမ်)',
          description: `📌 အနည်းဆုံး 3,000 ကစသွင်းပါကဒီပရိုမိုးရှင်းရနိုင်ပါတယ်ရှင့်။ <br><br>
                        📌 3,000 သွင်း 3,600 ရ ။ <br><br>
                        📌 5,000 သွင်း 6,000 ရ ။ <br><br>
                        📌 10,000 သွင်း 12,000 ရ ။ <br><br>
                        📌 နိုင်ငွေ 1ဆ ပြည့်မှသာ ငွေထုတ်ပေးပါမည်။ <br><br>
                        📌 နိုင်ငွေ ပြည့်ပြီးပါက ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။ <br><br>
                        📌 ဤပရိုမိုးရှင်းသည် ကာစီနိုမှလွဲ၍အခြားဂိမ်းများအားလုံးကစားနိုင်ပါသည်။`,
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/banners/2_promo.jpg',
        },
        {
          title: 'နေ့စဉ်ဘောနပ် 50% တစ်နေ့ (2ကြိမ်)',
          description: `📌 အနည်းဆုံး 3,000 ကစသွင်းပါကဒီပရိုမိုးရှင်းရနိုင်ပါတယ်ရှင့်။ <br><br>
                        📌 အမြင့်ဆုံးရယူနိုင်သည့် Bonus မှာ ငွေကျပ် 25,000 ဖြစ်ပါသည်။ <br><br>
                        📌 နိုင်ငွေ 3ဆ ပြည့်မှသာ ငွေထုတ်ပေးပါမည်။ <br><br>
                        📌 နိုင်ငွေ ပြည့်ပြီးပါက ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။ <br><br>
                        📌 ဤပရိုမိုးရှင်းသည် ကာစီနိုမှလွဲ၍အခြားဂိမ်းများအားလုံးကစားနိုင်ပါသည်။`,
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/banners/3_promo.jpg',
        },
        {
          title: 'နေ့စဥ်ဘောနပ် 30% တစ်နေ့(1ကြိမ်)',
          description: `📌 အနည်းဆုံး 3,000 ကစသွင်းပါကဒီပရိုမိုးရှင်းရနိုင်ပါတယ်ရှင့်။ <br><br>
                        📌 အမြင့်ဆုံးရယူနိုင်သည့် Bonus မှာ ငွေကျပ် 60,000 ဖြစ်ပါသည်။ <br><br>
                        📌 နိုင်ငွေ 3ဆ ပြည့်မှသာ ငွေထုတ်ပေးပါမည်။ <br><br>
                        📌 နိုင်ငွေ ပြည့်ပြီးပါက ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။ <br><br>
                        📌 ဤပရိုမိုးရှင်းသည် ကာစီနိုမှလွဲ၍အခြားဂိမ်းများအားလုံးကစားနိုင်ပါသည်။`,
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/banners/4_promo.jpg',
        },
        {
          title: 'စနေ ပိုမိုရိုးရှင်း 100% (တစ်နေ့2ကြိမ်)',
          description: `📌 အနည်းဆုံး 5,000 ကစသွင်းပါကဒီပိုမိုရိုးရှင်းရနိုင်ပါတယ်ရှင့်။ <br><br>
                        📌 အမြင့်ဆုံးရယူနိုင်သည့် Bonus မှာ ငွေကျပ် 20,000 ဖြစ်ပါသည်။ <br><br>
                        📌 5,000 သွင်း 10,000 ရ ။ <br><br>
                        📌 10,000 သွင်း 20,000 ရ ။ <br><br>
                        📌 20,000 သွင်း 40,000 ရ ။ <br><br>
                        📌 နိုင်ငွေ 4ဆ ပြည့်မှသာ ငွေထုတ်ပေးပါမည် <br><br>
                        📌 နိုင်ငွေ ပြည့်ပြီးပါက ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။ <br><br>
                        📌 ဤပရိုမိုးရှင်းသည် ကာစီနိုမှလွဲ၍အခြားဂိမ်းများအားလုံးကစားနိုင်ပါသည်။`,
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/banners/5_promo.jpg',
        },
        {
          title: 'တနင်္ဂနွေ ပရိုမိုးရှင်း 200% (တစ်ကြိမ်)',
          description: `📌 အနည်းဆုံး10,000 စသွင်းပါက ဒီပရိုမိုးရှင်းကို ရယူနိုင်ပါသည်။ <br><br>
                        📌 အမြင့်ဆုံးရယူနိုင်သည့် Bonus မှာ ငွေကျပ် 40,000ဖြစ်ပါသည်။ <br><br>
                        📌10,000သွင်း 30,000 ရ ။ <br><br>
                        📌20,000သွင်း 60,000 ရ ။ <br><br>
                        📌 နိုင်ငွေ 9ဆ ပြည့်မှသာ ငွေထုတ်ပေးပါမည်။ <br><br>
                        📌 နိုင်ငွေ ပြည့်ပြီးပါက သွင်းငွေရဲ့ 9ဆထုတ်ပေးပါသည်။ လက်ကျန်ငွေ အမောက်အားလုံး နှုတ်ပါတယ်ရှင့်။ <br><br>
                        📌 ဤပရိုမိုးရှင်းသည် ကာစီနိုမှလွဲ၍အခြားဂိမ်းများအားလုံးကစားနိုင်ပါသည်။`,
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/banners/6_promo.jpg',
        },
        {
          title: 'Super Night Promotion (တစ်နေ့1ကြိမ်)',
          description: `📌 အနည်းဆုံး 5,000 စသွင်းပါက ဒီပရိုမိုးရှင်းကိုရယူနိုင်ပါသည်။ <br><br>
                        📌 5,000 သွင်း 7,000 ရ ။ <br><br>
                        📌 ည 12 နာရီမှ မနက် 6 နာရီ အတွင်းရယူနိုင်ပါသည်။ <br><br>
                        📌 နိုင်ငွေ  1 ဆ ပြည့်လျှင် ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။ <br><br>
                        📌 ဤပရိုမိုးရှင်းသည် ကာစီနိုမှလွဲ၍အခြားဂိမ်းများအားလုံးကစားနိုင်ပါသည်။`,
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/banners/7_promo.jpg',
        },
        {
          title: 'ရူံးကြေးပြန်အမ်းငွေဘောနပ် 5%',
          description: `📌  တစ်ရက်အတွင်း ရူံးထားသောငွေ၏ 5% ကိုပြန်ရယူနိုင်ပါသည်။ <br><br>
                        📌  ရူံးကြေး ကို ည 12 နာရီနောက်ပိုင်းတွင် system မှ auto ပြန်အမ်းပေးပါသည်။ <br><br>
                        📌  နိုင်ငွေ 1 ဆ ပြည့်မှသာငွေထုတ်ပေးပါမည်။ <br><br>
                        📌  နိုင်ငွေ ပြည့်ပြီးပါက ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။`,
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/banners/8_promo.jpg',
        },


        {
          title: 'သွင်းတိုင်းရ10% ',
          description: `📌  အနည်းဆုံး5000ကစသွင်းပါကဒီပရိုမိုးရှင်းရနိုင်ပါတယ်ရှင့်။ <br><br>                        
                        📌  အမြင့်ဆုံးရယူနိုင်သည့် Bonus မှာ ငွေကျပ် 30,000 ဖြစ်ပါသည်။ <br><br>                   
                        📌  နိုင်ငွေ 2ဆ ပြည့်မှသာ ငွေထုတ်ပေးပါမည်။ <br><br>                                
                        📌  နိုင်ငွေ ပြည့်ပြီးပါက ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။ <br><br>
                        📌  ဤပရိုမိုးရှင်းသည် ကာစီနိုမှလွဲ၍အခြားဂိမ်းများအားလုံးကစားနိုင်ပါသည်။`,
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/banners/9_promo.jpg',
        },
        {
          title: 'တစ်ပတ်သွင်းငွေ ပရိုမိုးရှင်း',
          description: `📌 တပတ် သွင်းငွေ 300,000  ရှိလျှင် နိုင်နိုင်ရှူံးရှူံး  Free 5,000 ကျပ် ရယူလိုက်ပါ။ <br><br>
                        📌 အမြင့်ဆုံးရယူနိုင်သည့် သွင်းငွေအမောက် 3,000,000 ကျပ် ဖြစ်ပြီးFree 50,000ကျပ်ဖြစ်ပါသည်။ <br><br>
                        📌 စနေ့နေ့ မနက် 1 နာရီမှ မနက် 4 နာရီအတွင်း သာရယူနိုင်ပါသည်။ <br><br>
                        📌 သတ်မှတ်ချိန် ကျော်လွန်သွားလျှင် တစ်ပတ် သွင်းငွေပရိုမိုးရှင်း မရယူနိုင်တော့ပါ။ <br><br>
                        📌  နိုင်ငွေ 1ဆ ပြည့်လျှင် ငွေထုတ်ယူနိုင်ပါသည်။ <br><br>
                        📌  ဤပရိုမိုးရှင်းသည် ကာစီနိုမှလွဲ၍အခြားဂိမ်းများအားလုံးကစားနိုင်ပါသည်။ <br><br> <br><br>

                        မှတ်ချက် <br>
                        📍 တစ်ပတ်ပြည့်ပီးပါက အသစ် ပြန်လည်တွက်ယူပါသည်။`,
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/melon-zeed/banners/10_promo.jpg',
        },
      ]
    };
  },
  methods: {
    promotionIndex(index) {
      this.selectedPromotion = index;
    },
    // async fetchWebsiteData() {
    //   try {
    //     const res = await axios.get('/website-infos');
    //     this.websiteInfo = res.data.data[0];
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
  async mounted() {
    // this.$store.commit('setLoading', true);
    // await this.fetchWebsiteData();
    // this.$store.commit('setLoading', false);
  },
};
</script>
<style scoped>
.detail-button {
  appearance: none;
  background-color: transparent;
  border: 1px solid #552A07;
  text-transform: uppercase;
  border-radius: 14px;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 0.9rem;
  font-weight: 450;
  line-height: normal;
  margin: 0;
  min-height: 15px;
  min-width: 0;
  outline: none;
  padding: 1px 5px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.detail-button:disabled {
  pointer-events: none;
}

.detail-button:hover {
  color: #fff;
  background: #2B2C2E;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px; */
  transform: translateY(-2px);
  /* border: black !important; */
}

.detail-button:active {
  box-shadow: none;
  transform: translateY(0);
  /* border: black !important; */
  background: #2B2C2E;
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #B50200 0%,
    #f3e706 29%,
    #B50200 67%,
    #f3e706 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
</style>